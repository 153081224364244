import { AppBar, IconButton, Typography } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles } from '@material-ui/styles';
import * as Actions from 'app/auth/store/actions/user.actions.js';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setInvitationsDialogOpen, setUserDetailsDialogOpen } from '../../auth/store/UserSlice';
import EnhancedTranslate from '../../common-components/EnhancedTranslate';
import TranslationTooltip from '../../common-components/TranslationTooltip';
import * as MessageActions from '../../store/actions';
import * as CommonActions from '../../store/actions/common/common.actions';

const useStyles = makeStyles(theme => ({
  root: {
    '&.sidebar-hidable-block': {
      '& .sidebar-hidable-content': {
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut
        })
      }
    }
  }
}));

function UserNavbarHeader() {
  const dispatch = useDispatch();

  const user = useSelector(({ auth }) => auth.user);
  const { invitations } = useSelector(state => state.user);

  const classes = useStyles();

  function openInvitationsDialog() {
    dispatch(setInvitationsDialogOpen(true));
  }

  return (
    <AppBar
      position='static'
      color='primary'
      elevation={0}
      classes={{ root: classes.root }}
      className='sidebar-hidable-block relative flex flex-col items-center justify-center pt-24 pb-64 mb-32 z-0 app-bar'
    >
      {invitations.length > 0 && (
        <TranslationTooltip title='Invitations'>
          <IconButton onClick={openInvitationsDialog}>
            <Badge badgeContent={invitations.length} color='secondary'>
              <MailIcon color='primary' />
            </Badge>
          </IconButton>
        </TranslationTooltip>
      )}
      <div
        className={'flex mt-8 cursor-pointer sidebar-hidable-content'}
        onClick={() => {
          dispatch(setUserDetailsDialogOpen(true));
        }}
      >
        <div className='flex ml-8 mr-8 break-all'>
          <Typography className='text-16' color='inherit'>
            {`${user.name} ${user.surname}`}
          </Typography>
          <EditIcon className='ml-8' fontSize='small' color='primary' />
        </div>
      </div>

      <Button
        style={{ marginTop: '10px' }}
        className='sidebar-hidable-content color-black'
        variant='outlined'
        color='primary'
        onClick={() => {
          dispatch(Actions.logoutUser());
          dispatch(CommonActions.setErrorFalse());
          dispatch(
            MessageActions.showMessage({
              message: <EnhancedTranslate value='Successfully logged out' />
            })
          );
        }}
      >
        <EnhancedTranslate value='Logout' />
      </Button>
    </AppBar>
  );
}

export default UserNavbarHeader;
