import { Button, CardContent, InputAdornment, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import newLogo from '../../../assets/fullCwatchLogo.jpg';
import { sendRequestForNewPassword, setInNewPasswordRequestForm } from '../../auth/store/LoginSlice';
import EnhancedTextField from '../../common-components/EnhancedTextField';
import EnhancedTranslate from '../../common-components/EnhancedTranslate';
import GoBackToLoginButton from '../GoBackToLoginButton';
import LoginFooter from '../LoginFooter';

export default function NewPasswordRequestForm() {
  const dispatch = useDispatch();

  const { newPasswordRequestForm } = useSelector(state => state.login);
  const i18n = useSelector(({ i18n }) => i18n);

  const { email } = newPasswordRequestForm;

  return (
    <CardContent className='flex h-full flex-col items-center justify-center p-32'>
      <GoBackToLoginButton />
      <img className='m-32 w-256' src={newLogo} alt='logo' />
      <Typography variant='h6' className='mb-32 mt-16'>
        <EnhancedTranslate value={'New Password'} />
      </Typography>
      <div className='flex w-full flex-col justify-center'>
        <EnhancedTextField
          name={'email'}
          value={email}
          label={'Email'}
          variant='outlined'
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <EmailIcon className='text-20' color='action' />
              </InputAdornment>
            )
          }}
          onChange={event => {
            dispatch(
              setInNewPasswordRequestForm({
                key: 'email',
                value: event.target.value
              })
            );
          }}
        />

        <Button
          type='button'
          variant='contained'
          onClick={() => dispatch(sendRequestForNewPassword(email, i18n.locale))}
          color='secondary'
          className='mx-auto mb-24 mt-16 w-full'
        >
          <EnhancedTranslate value={'Set new password'} />
        </Button>

        <ToastContainer />
      </div>
      <div className={'flex-1'} />
      <LoginFooter />
    </CardContent>
  );
}
