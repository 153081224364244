import { Typography, makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EnhancedTranslate from '../../../../common-components/EnhancedTranslate';
import HelpIcon from '../../../../common-components/Icons/HelpIcon';
import JobTitlesNestedMenuList from '../JobTitlesNestedMenuList';
import { setButtonSettingsForm } from '../store/DevicesSlice';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: 80,
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
    paddingBlock: 32,
    paddingInline: 16,
    '@media (min-width: 600px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 16
    }
  }
}));

export default function ButtonAssignmentsSettingsForm() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const jobTitlesWithUsers = useSelector(({ forSelect }) => forSelect.forSelect.jobTitlesWithUsers);
  const { buttonSettingsForm } = useSelector(state => state.devicesSlice);

  function handlePrimaryJobTitleCheck(jobTitleId, checked) {
    if (buttonSettingsForm.secondaryJobTitles.includes(jobTitleId) && checked) return;
    if (checked) {
      const userIdsForThisJobTitle = jobTitlesWithUsers.find(x => x.id === jobTitleId).users.map(x => x.id);

      dispatch(
        setButtonSettingsForm({
          ...buttonSettingsForm,
          primaryJobTitles: [...buttonSettingsForm.primaryJobTitles, jobTitleId],
          primaryUids: buttonSettingsForm.primaryUids.filter(x => !userIdsForThisJobTitle.includes(x))
        })
      );
    } else
      dispatch(
        setButtonSettingsForm({
          ...buttonSettingsForm,
          primaryJobTitles: buttonSettingsForm.primaryJobTitles.filter(x => x !== jobTitleId)
        })
      );
  }

  function handleSecondaryJobTitleCheck(jobTitleId, checked) {
    if (buttonSettingsForm.primaryJobTitles.includes(jobTitleId) && checked) return;
    if (checked) {
      const userIdsForThisJobTitle = jobTitlesWithUsers.find(x => x.id === jobTitleId).users.map(x => x.id);
      dispatch(
        setButtonSettingsForm({
          ...buttonSettingsForm,
          secondaryJobTitles: [...buttonSettingsForm.secondaryJobTitles, jobTitleId],
          primaryUids: buttonSettingsForm.primaryUids.filter(x => !userIdsForThisJobTitle.includes(x))
        })
      );
    } else
      dispatch(
        setButtonSettingsForm({
          ...buttonSettingsForm,
          secondaryJobTitles: buttonSettingsForm.secondaryJobTitles.filter(x => x !== jobTitleId)
        })
      );
  }

  function handlePrimaryUserCheck(userId, checked, jobTitleId) {
    if (buttonSettingsForm.secondaryUids.includes(userId) && checked) return;
    if (checked)
      dispatch(
        setButtonSettingsForm({
          ...buttonSettingsForm,
          primaryUids: [...buttonSettingsForm.primaryUids, userId]
        })
      );
    else {
      if (buttonSettingsForm.primaryJobTitles.includes(jobTitleId)) {
        let allUsersForThisJobTitleWithoutSelectedOne = jobTitlesWithUsers
          .find(x => x.id === jobTitleId)
          .users.map(x => x.id)
          .filter(x => x !== userId);
        dispatch(
          setButtonSettingsForm({
            ...buttonSettingsForm,
            primaryJobTitles: buttonSettingsForm.primaryJobTitles.filter(x => x !== jobTitleId),
            primaryUids: [...buttonSettingsForm.primaryUids, ...allUsersForThisJobTitleWithoutSelectedOne]
          })
        );
      } else {
        dispatch(
          setButtonSettingsForm({
            ...buttonSettingsForm,
            primaryUids: buttonSettingsForm.primaryUids.filter(x => x !== userId)
          })
        );
      }
    }
  }

  function handleSecondaryUserCheck(userId, checked, jobTitleId) {
    if (buttonSettingsForm.primaryUids.includes(userId) && checked) return;
    if (checked)
      dispatch(
        setButtonSettingsForm({
          ...buttonSettingsForm,
          secondaryUids: [...buttonSettingsForm.secondaryUids, userId]
        })
      );
    else {
      if (buttonSettingsForm.secondaryJobTitles.includes(jobTitleId)) {
        let allUsersForThisJobTitleWithoutSelectedOne = jobTitlesWithUsers
          .find(x => x.id === jobTitleId)
          .users.map(x => x.id)
          .filter(x => x !== userId);
        dispatch(
          setButtonSettingsForm({
            ...buttonSettingsForm,
            secondaryJobTitles: buttonSettingsForm.secondaryJobTitles.filter(x => x !== jobTitleId),
            secondaryUids: [...buttonSettingsForm.secondaryUids, ...allUsersForThisJobTitleWithoutSelectedOne]
          })
        );
      } else {
        dispatch(
          setButtonSettingsForm({
            ...buttonSettingsForm,
            secondaryUids: buttonSettingsForm.secondaryUids.filter(x => x !== userId)
          })
        );
      }
    }
  }

  return (
    <div className={classes.container}>
      <div style={{ width: '100%', flex: 1 }}>
        <div
          style={{
            border: '1px solid #BDBDBD',
            borderRadius: '4px 4px 0px 0px',
            padding: 16
          }}
        >
          <Tooltip title={<EnhancedTranslate value={'primary-assigness-info'} />}>
            <Typography className={'typography-body2'}>
              <EnhancedTranslate value={'Primary Assignees'} />
              <HelpIcon />
            </Typography>
          </Tooltip>
        </div>
        <div
          style={{
            border: '1px solid #BDBDBD',
            borderRadius: '0px 0px 4px 4px',
            borderTop: 0,
            minHeight: 403,
            height: '100%'
          }}
        >
          <JobTitlesNestedMenuList
            selectedJobTitles={buttonSettingsForm.primaryJobTitles ? buttonSettingsForm.primaryJobTitles : []}
            selectedUsers={buttonSettingsForm.primaryUids ? buttonSettingsForm.primaryUids : []}
            handleJobTitleCheck={handlePrimaryJobTitleCheck}
            handleUserCheck={handlePrimaryUserCheck}
          />
        </div>
      </div>
      <div style={{ width: '100%', flex: 1 }}>
        <div
          style={{
            border: '1px solid #BDBDBD',
            borderRadius: '4px 4px 0px 0px',
            padding: 16
          }}
        >
          <Tooltip title={<EnhancedTranslate value={'secondary-assigness-info'} />}>
            <Typography className={'typography-body2'}>
              <EnhancedTranslate value={'Secondary Assignees'} />
              <HelpIcon />
            </Typography>
          </Tooltip>
        </div>
        <div
          style={{
            border: '1px solid #BDBDBD',
            borderRadius: '0px 0px 4px 4px',
            borderTop: 0,
            minHeight: 403,
            height: '100%'
          }}
        >
          <JobTitlesNestedMenuList
            selectedJobTitles={buttonSettingsForm.secondaryJobTitles ? buttonSettingsForm.secondaryJobTitles : []}
            selectedUsers={buttonSettingsForm.secondaryUids ? buttonSettingsForm.secondaryUids : []}
            handleJobTitleCheck={handleSecondaryJobTitleCheck}
            handleUserCheck={handleSecondaryUserCheck}
          />
        </div>
      </div>
    </div>
  );
}
