import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { polishPlural, shortSerial } from '../../../../lib/commonFunctions';
import {
  CWATCH_COLOR_DEFAULT,
  DEVICE_TYPES_MAP,
  LOCALSTORAGE_PACKAGES_LAST_USED_OBID,
  STANDARIZED_DEVICE_TYPE_CWATCH
} from '../../../../lib/constants';
import { getCurrentSelectedObid } from '../../../../lib/getObid';
import EnhancedTranslate from '../../../common-components/EnhancedTranslate';
import { useRoles } from '../../../custom-hooks/useRoles';
import {
  assignPackageToObject,
  assignSerialToObject,
  getPackageById,
  getSerialToAdd,
  setPackageId,
  setSerialToAdd
} from '../../../store/actions';

const useStyles = makeStyles(() => ({
  dialogActions: {
    padding: '0 24px 24px 24px',
    justifyContent: 'space-between'
  }
}));

// eslint-disable-next-line complexity
export function AddDevicesFromPackageModal() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isPartner } = useRoles();

  const packageId = useSelector(({ common }) => common.common.packageId);
  const serialToAdd = useSelector(({ common }) => common.common.serialToAdd);
  const packageEntity = useSelector(({ common }) => common.common.package);
  const serialWithAssignments = useSelector(({ common }) => common.common.serialWithAssignments);
  const objects = useSelector(({ common }) => common.common.objects);

  const [selectedObidToAssignDevices, setSelectedObidToAssignDevices] = useState(getDefaultSelectedObid());

  function closeComposeDialog() {
    if (packageId) dispatch(setPackageId(null));
    if (serialToAdd) dispatch(setSerialToAdd(null));
  }

  function getDefaultSelectedObid() {
    const lastUsedObid = localStorage.getItem(LOCALSTORAGE_PACKAGES_LAST_USED_OBID);
    if (lastUsedObid && hasAccessToObject(lastUsedObid)) {
      return lastUsedObid;
    }
    return getCurrentSelectedObid();
  }

  function hasAccessToMoreThanOneObject() {
    return objects && objects.length > 1;
  }

  useEffect(() => {
    if (packageId) {
      dispatch(getPackageById(packageId));
    }
    if (serialToAdd) {
      dispatch(getSerialToAdd(serialToAdd));
    }
  }, [packageId, serialToAdd]);

  function getDeviceImgSrc(device, color) {
    return `${process.env.PUBLIC_URL}/assets/devicesIcons/${device}-${color}.svg`;
  }

  function mapDeviceTypeFromSerialToStandarizedDeviceType(deviceType) {
    if (!deviceType) return STANDARIZED_DEVICE_TYPE_CWATCH;
    else return DEVICE_TYPES_MAP[deviceType];
  }

  function getObjectNamesSelect() {
    let rv = [];
    for (let object of objects) {
      rv.push({ label: object.name, value: object.id });
    }
    return rv;
  }

  function submit() {
    if (isPackageView()) dispatch(assignPackageToObject(packageId, selectedObidToAssignDevices, isPartner));
    else if (isSerialView())
      dispatch(assignSerialToObject(serialToAdd, selectedObidToAssignDevices, serialWithAssignments.obid, isPartner));

    closeComposeDialog();
  }

  function hasAccessToObject(obid) {
    for (let object of objects) {
      if (String(object.id) === String(obid)) return true;
    }
    return false;
  }

  function canBeAdded(serials) {
    let devicesThatCanBeAddedCounter = 0;
    for (let serial of serials) {
      if (
        hasAccessToObject(selectedObidToAssignDevices) &&
        (serial.obid === '' || (serial.obid !== '' && String(serial.obid) !== String(selectedObidToAssignDevices)))
      )
        devicesThatCanBeAddedCounter++;
    }
    return devicesThatCanBeAddedCounter > 0;
  }

  function isOpen() {
    return isPackageView() || isSerialView();
  }

  function isPackageView() {
    return packageId !== null;
  }

  function isSerialView() {
    return serialToAdd !== null;
  }

  if (!isOpen()) return null;

  let serials = [],
    device,
    color;
  if (isPackageView() && packageEntity) {
    serials = packageEntity.serials;
    device = packageEntity.device;
    color = packageEntity.color;
  } else if (isSerialView() && serialWithAssignments) {
    serials = [serialWithAssignments];
    device = mapDeviceTypeFromSerialToStandarizedDeviceType(serialWithAssignments.deviceType);
    color = CWATCH_COLOR_DEFAULT;
  }

  return (
    <Dialog
      classes={{
        paper: 'm-24'
      }}
      open={isOpen()}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth='xs'
    >
      <AppBar position='static' elevation={1}>
        <Toolbar className='flex w-full'>
          <Typography variant='subtitle1' color='inherit'>
            {isPackageView() && <EnhancedTranslate value={'Add devices from QR code'} />}
            {isSerialView() && <EnhancedTranslate value={'Add device from QR code'} />}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent classes={{ root: 'p-24' }}>
        {isOpen() && serials.length === 0 && (
          <Fragment>
            <Typography className={'h3'}>
              <EnhancedTranslate value={'Devices from the provided QR code could not be found'} />
            </Typography>
          </Fragment>
        )}
        {isOpen() && serials.length > 0 && (
          <Fragment>
            {hasAccessToMoreThanOneObject() && (
              <Fragment>
                <div className='text-center'>
                  <EnhancedTranslate value={'Add to'} />:
                </div>
                <TextField
                  select
                  className={'mb-16'}
                  SelectProps={{
                    IconComponent: () => null
                  }}
                  variant='outlined'
                  value={selectedObidToAssignDevices}
                  onChange={e => {
                    setSelectedObidToAssignDevices(e.target.value);
                  }}
                  fullWidth
                >
                  {getObjectNamesSelect().map(obj => (
                    <MenuItem key={obj.value} value={obj.value}>
                      {obj.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Fragment>
            )}
            {serials.length} <EnhancedTranslate value={`Device_${polishPlural(serials.length)}`} />
            <br />
            {serials &&
              serials.map(serial => (
                <div key={serial.serial} className='mt-16'>
                  <div className='flex justify-between'>
                    <div>
                      <img
                        style={{
                          width: '4em',
                          height: '4em',
                          display: 'inline'
                        }}
                        src={getDeviceImgSrc(device, color)}
                        alt='img'
                      />
                    </div>
                    <div
                      style={{
                        alignSelf: 'center',
                        flex: '2'
                      }}
                    >
                      <div className='flex justify-between'>
                        <div>{device}</div>
                        <div>{shortSerial(serial.serial)}</div>
                      </div>
                      <div className='flex'>
                        {hasAccessToObject(serial.obid) &&
                          serial.obid !== '' &&
                          String(serial.obid) !== String(selectedObidToAssignDevices) && (
                            <span
                              style={{
                                color: 'orange'
                              }}
                            >
                              {I18n.t('currently_assigned_to_known_object', {
                                object: serial.object_name
                              })}
                            </span>
                          )}
                        {!hasAccessToObject(serial.obid) &&
                          serial.obid !== '' &&
                          String(serial.obid) !== String(selectedObidToAssignDevices) && (
                            <span
                              style={{
                                color: 'red'
                              }}
                            >
                              <EnhancedTranslate value="Device will not be added, it's assigned to another object" />
                            </span>
                          )}
                        {serial.obid !== '' && String(serial.obid) === String(selectedObidToAssignDevices) && (
                          <span
                            style={{
                              color: 'orange'
                            }}
                          >
                            <EnhancedTranslate value="Device will not be added, it's assigned to currently selected object" />
                          </span>
                        )}
                        {serial.obid === '' && (
                          <span
                            style={{
                              color: 'green'
                            }}
                          >
                            <EnhancedTranslate value='Device will be added' />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Fragment>
        )}
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <div />
        <div>
          <Button variant='outlined' onClick={closeComposeDialog} color={'secondary'}>
            <EnhancedTranslate value={'Close'} />
          </Button>
          {isOpen() && (
            <Button
              className={'ml-16'}
              variant='contained'
              onClick={submit}
              color={'secondary'}
              disabled={!canBeAdded(serials)}
            >
              <EnhancedTranslate value={'Add'} />
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
}
