import { makeStyles } from '@material-ui/styles';
import * as Actions from 'app/main/apps/devices/store/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEVICE_TYPE_WATCH } from '../../../../shared/functions/device-type';

const useStyles = makeStyles(() => ({
  watch: {
    borderRadius: '300px'
  }
}));

export default function ScreenshotImage({ src }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedDeviceType = useSelector(({ devices }) => devices.devices.selectedDeviceType);

  function onImageLoad() {
    dispatch(Actions.setScreenshotImageLoadingFalse());
  }

  function classesByDeviceType() {
    if (selectedDeviceType === DEVICE_TYPE_WATCH) return classes.watch;
    return '';
  }

  return <img className={classesByDeviceType()} alt={'screenshot'} onLoad={onImageLoad} src={src} />;
}
