import { useTimeout } from '@fuse/hooks';
import { LinearProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import EnhancedTranslate from '../../../app/common-components/EnhancedTranslate';

function FuseLoading(props) {
  const [showLoading, setShowLoading] = useState(!props.delay);

  useTimeout(() => {
    setShowLoading(true);
  }, props.delay);

  if (!showLoading) {
    return null;
  }

  return (
    <div className='flex flex-1 flex-col items-center justify-center'>
      <Typography className='mb-16 text-20' color='textSecondary'>
        <EnhancedTranslate value={'Loading'} />
      </Typography>
      <LinearProgress className='w-xs' color='secondary' />
    </div>
  );
}

FuseLoading.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
};

FuseLoading.defaultProps = {
  delay: false
};

export default FuseLoading;
