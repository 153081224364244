import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { COLOR_BLUE_100, COLOR_GREY_60 } from '../../lib/colors';

const useStyles = makeStyles(theme => ({
  linkText: {
    color: COLOR_BLUE_100,
    textDecoration: 'none',
    fontWeight: 700,
    cursor: 'pointer'
  },
  disabled: {
    color: COLOR_GREY_60,
    cursor: 'default'
  }
}));

const LinkText = ({ children, onClick, disabled = false }) => {
  const classes = useStyles();

  return (
    <Typography
      component='span'
      className={clsx(classes.linkText, {
        [classes.disabled]: disabled
      })}
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      {children}
    </Typography>
  );
};

export default LinkText;
