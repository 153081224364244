import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';

import { CURRENT_PASSWORD_INCORRECT } from '../../../lib/constants';
import { showMessage } from '../../store/actions';
import { RESET_STORE } from '../../store/actions/reduxActions';
import { SET_USER_DATA } from './actions';

export const initialState = {
  editUserDialog: {
    open: false,
    currentPasswordIncorrect: false
  },
  invitationsDialog: {
    open: false
  },
  invitations: []
};

export const submitUserDetails = user => async dispatch => {
  axios
    .post(`/api/dashboard-users/details`, user)
    .then(() => {
      dispatch({
        type: SET_USER_DATA,
        payload: user
      });
      dispatch(setUserDetailsDialogOpen(false));
      dispatch(setCurrentPasswordIncorrect(false));
    })
    .catch(error => {
      if (error?.response?.data?.error === CURRENT_PASSWORD_INCORRECT) {
        dispatch(setCurrentPasswordIncorrect(true));
      }
    });
};

export const updateInvitations = (acceptedFacilitiesIds, declinedFacilitiesIds) => async dispatch => {
  axios
    .post(`/api/dashboard-users/invitations`, {
      acceptedFacilitiesIds,
      declinedFacilitiesIds
    })
    .then(() => {
      dispatch(fetchFacilitiesInvitations());
      dispatch(
        showMessage({
          message: I18n.t('Invitations updated successfully'),
          variant: 'success'
        })
      );
    })
    .finally(() => {
      dispatch(setInvitationsDialogOpen(false));
    });
};

export const fetchFacilitiesInvitations = user => async dispatch => {
  axios.get(`/api/dashboard-users/invitations`, user).then(response => {
    dispatch(setInvitations(response.data));
  });
};

const devicesSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetailsDialogOpen: (state, action) => {
      state.editUserDialog.open = action.payload;
    },
    setInvitationsDialogOpen: (state, action) => {
      state.invitationsDialog.open = action.payload;
    },
    setCurrentPasswordIncorrect: (state, action) => {
      state.editUserDialog.currentPasswordIncorrect = action.payload;
    },
    setInvitations: (state, action) => {
      state.invitations = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(RESET_STORE, state => {
      return { ...initialState };
    });
  }
});
export const { setUserDetailsDialogOpen, setInvitationsDialogOpen, setCurrentPasswordIncorrect, setInvitations } =
  devicesSlice.actions;
export default devicesSlice.reducer;
