import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { I18n } from 'react-redux-i18n';

import { LANGUAGES } from '../../../lib/languages';
import EnhancedTextField from '../EnhancedTextField';
import EnhancedTranslate from '../EnhancedTranslate';
import Flag from '../Icons/Flag';

export default function LanguagesSelect(props) {
  function getSortedLanguages() {
    let options = LANGUAGES.map(language => {
      return {
        value: language.code,
        name: I18n.t(language.name)
      };
    });
    return options.sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <EnhancedTextField {...props} className='mt-16' label={'Language'} fullWidth select variant='outlined'>
      {getSortedLanguages().map(
        lang =>
          lang && (
            <MenuItem key={lang.value} value={lang.value}>
              {lang && <Flag lang={lang.value} />}

              {'\u00A0'}
              {'\u00A0'}
              <EnhancedTranslate value={lang.name} />
            </MenuItem>
          )
      )}
    </EnhancedTextField>
  );
}
