import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import _ from '../../../../@lodash';
import { RESET_STORE } from '../../actions/reduxActions';

export const initialState = {
  objectDetails: null,
  objectAmountOfDevices: {},
  objectAmountOfDevicesLoading: false,
  buttonThemes: []
};

export const fetchObjectDetails = obid => async dispatch => {
  axios.get(`/api/objects/${obid}`).then(res => {
    dispatch(setObjectDetails(res.data));
  });
};

export const fetchObjectAmountOfDevices = obid => async dispatch => {
  dispatch(setObjectAmountOfDevicesLoading(true));
  axios.get(`/api/objects/${obid}/get-amount-of-devices`).then(res => {
    dispatch(setObjectAmountOfDevices(res.data));
    dispatch(setObjectAmountOfDevicesLoading(false));
  });
};

export const fetchButtonThemes = () => async dispatch => {
  axios.get(`/api/buttons/themes`).then(res => {
    dispatch(setButtonThemes(res.data));
  });
};

const objectsSlice = createSlice({
  name: 'objects',
  initialState,
  reducers: {
    setObjectDetails: (state, action) => {
      state.objectDetails = action.payload;
    },
    setObjectAmountOfDevices: (state, action) => {
      state.objectAmountOfDevices = _.keyBy(action.payload, 'type');
    },
    setObjectAmountOfDevicesLoading: (state, action) => {
      state.objectAmountOfDevicesLoading = action.payload;
    },
    setButtonThemes: (state, action) => {
      state.buttonThemes = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(RESET_STORE, state => {
      return { ...initialState };
    });
  }
});

export const { setObjectDetails, setObjectAmountOfDevices, setObjectAmountOfDevicesLoading, setButtonThemes } =
  objectsSlice.actions;

export default objectsSlice.reducer;
