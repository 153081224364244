import { Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import * as DevicesActions from 'app/main/apps/devices/store/actions';
import * as PartnerActions from 'app/main/apps/partner/store/actions';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { QR_CODE_SERIAL_URL } from '../../../../../../lib/constants';
import EnhancedTranslate from '../../../../../common-components/EnhancedTranslate';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function AddNewDevicesDialog() {
  const dispatch = useDispatch();
  const addNewDeviceDialogOpen = useSelector(({ devices }) => devices.devices.addNewDeviceDialogOpen);
  const pairDevicesOpenDialog = useSelector(
    ({ partnerReducer }) => partnerReducer.partnerReducer.pairDevicesOpenDialog
  );
  const partnerSelectedObid = useSelector(
    ({ partnerReducer }) => partnerReducer.partnerReducer.pairDevicesSelectedObid
  );
  const [serials, setSerials] = useState('');

  function closeComposeDialog() {
    addNewDeviceDialogOpen
      ? dispatch(DevicesActions.closeAddNewDeviceToObjectDialog())
      : dispatch(PartnerActions.pairDeviceCloseDialog());
  }

  function sendRequest() {
    const serial = serials
      .replace(/(\d\d\d)-(\d\d\d)/g, '$1$2')
      .split(/[\s,;]+/)
      .filter(String);
    addNewDeviceDialogOpen
      ? dispatch(DevicesActions.addNewDevicesBySerials(serial))
      : dispatch(PartnerActions.pairDevicesBySerials(serial, partnerSelectedObid));
    closeComposeDialog();
    setTimeout(() => {
      dispatch(DevicesActions.getDevices(false));
    }, 1000);
    setSerials('');
  }

  function handleChangeInput(value) {
    if (value.includes(QR_CODE_SERIAL_URL)) {
      value = value.replace(QR_CODE_SERIAL_URL, '');
    }
    setSerials(value);
  }

  function isOpen() {
    return addNewDeviceDialogOpen || pairDevicesOpenDialog;
  }

  return (
    <Dialog
      open={isOpen()}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeComposeDialog}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle id='alert-dialog-slide-title'>
        <EnhancedTranslate value={'Adding devices to the object'} />
      </DialogTitle>
      <DialogContent>
        <TextField
          onChange={e => handleChangeInput(e.target.value)}
          value={serials}
          placeholder={I18n.t('Serials of devices to add, separated by newlines')}
          className='flex flex-1'
          rows='25'
          variant='outlined'
          fullWidth
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeComposeDialog} color='primary'>
          <EnhancedTranslate value={'Cancel'} />
        </Button>
        <Button onClick={sendRequest} color='primary'>
          <EnhancedTranslate value={'Add'} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddNewDevicesDialog;
