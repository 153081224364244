import { Button, CardContent, CircularProgress, Fade, InputAdornment, Paper, Typography } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import EmailIcon from '@material-ui/icons/Email';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import fullCwatchLogo from '../../../assets/fullCwatchLogo.jpg';
import { fetchNewPasswordLinkDetails, setInNewPasswordForm, setNewPassword } from '../../auth/store/LoginSlice';
import EnhancedTextField from '../../common-components/EnhancedTextField';
import EnhancedTranslate from '../../common-components/EnhancedTranslate';
import PasswordTextField from '../../common-components/TextFields/PasswordTextField';
import useResize from '../../custom-hooks/useResize';
import PasswordsValidationRules from '../../fuse-layouts/shared-components/PasswordsValidationRules';
import GoBackToLoginButton from '../GoBackToLoginButton';
import LoginFooter from '../LoginFooter';

export default function NewPasswordForm() {
  const dispatch = useDispatch();

  const { newPassword, token } = useSelector(state => state.login);

  const { form, linkDetailsLoading, linkDetails, linkDetailsError } = newPassword;
  const { email, password, passwordConfirmation } = form;

  const passwordRulesPopperAnchor = useRef(null);
  const isPasswordRulesPopperOpen = useResize(1200);

  const [arePasswordsValid, setArePasswordsValid] = useState(false);

  useEffect(() => {
    dispatch(fetchNewPasswordLinkDetails(token));
  }, []);

  useEffect(() => {
    dispatch(
      setInNewPasswordForm({
        key: 'email',
        value: linkDetails.userEmail
      })
    );
  }, [linkDetails]);

  function submit() {
    dispatch(setNewPassword(email, password, token));
  }

  function canBeSubmitted() {
    return !!email && !!password && !!passwordConfirmation && arePasswordsValid;
  }

  return (
    <CardContent className='flex h-full flex-col items-center justify-center p-32'>
      <GoBackToLoginButton />
      <img className='mx-32 w-256' src={fullCwatchLogo} alt='logo' />
      {linkDetailsLoading ? (
        <div className={'mt-16 flex justify-center'}>
          <CircularProgress color={'secondary'} />
        </div>
      ) : (
        <div className={'mt-16'}>
          {linkDetailsError !== null ? (
            <Alert severity={'error'}>
              <EnhancedTranslate value={linkDetailsError} />
            </Alert>
          ) : (
            <>
              <Typography variant='h6' className='mb-16 mt-16 text-center'>
                <EnhancedTranslate value={'New Password'} />
              </Typography>

              <div className='w-full'>
                <EnhancedTextField
                  name={'email'}
                  value={email}
                  label={'Email'}
                  variant='outlined'
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off'
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <EmailIcon className='text-20' color='action' />
                      </InputAdornment>
                    )
                  }}
                  onChange={event => {
                    dispatch(
                      setInNewPasswordForm({
                        key: 'email',
                        value: event.target.value
                      })
                    );
                  }}
                />

                {!isPasswordRulesPopperOpen && (
                  <div className={'mt-16'}>
                    <PasswordsValidationRules
                      password={password}
                      passwordConfirmation={passwordConfirmation}
                      setArePasswordsValid={setArePasswordsValid}
                    />
                  </div>
                )}

                <PasswordTextField
                  className='mt-16'
                  label={'Password'}
                  value={password}
                  onChange={newValue => {
                    dispatch(
                      setInNewPasswordForm({
                        key: 'password',
                        value: newValue
                      })
                    );
                  }}
                />

                <PasswordTextField
                  className='mt-16'
                  label={'Repeat password'}
                  value={passwordConfirmation}
                  onChange={newValue => {
                    dispatch(
                      setInNewPasswordForm({
                        key: 'passwordConfirmation',
                        value: newValue
                      })
                    );
                  }}
                />

                <Popper
                  open={isPasswordRulesPopperOpen}
                  placement='right'
                  disablePortal={false}
                  anchorEl={passwordRulesPopperAnchor.current}
                  transition
                  modifiers={{
                    flip: {
                      enabled: false
                    },
                    offset: {
                      enabled: true,
                      offset: '-300, 220'
                    }
                  }}
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper>
                        <div className='p-16'>
                          <PasswordsValidationRules
                            password={password}
                            passwordConfirmation={passwordConfirmation}
                            setArePasswordsValid={setArePasswordsValid}
                          />
                        </div>
                      </Paper>
                    </Fade>
                  )}
                </Popper>

                <Button
                  variant='contained'
                  color='secondary'
                  className='mx-auto mb-24 mt-16 w-full'
                  disabled={!canBeSubmitted()}
                  onClick={submit}
                >
                  <EnhancedTranslate value={'Set'} />
                </Button>
              </div>
            </>
          )}
        </div>
      )}

      <div ref={passwordRulesPopperAnchor} />
      <ToastContainer />

      <div className={'flex-1'} />
      <LoginFooter />
    </CardContent>
  );
}
