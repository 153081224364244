import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import useGeocoderService from '../../../../../../custom-hooks/googleServices/useGeocoderService';

export default function LastKnowLocation() {
  const geocoderService = useGeocoderService();

  const { location } = useSelector(state => state.devicesSlice.devicesLocationDialog);

  const [loading, setLoading] = useState(true);
  const [locationName, setLocationName] = useState('');

  useEffect(() => {
    if (location)
      geocoderService.current.geocode(
        {
          location: {
            lat: location.location.lat,
            lng: location.location.lng
          }
        },
        (results, status) => {
          setLoading(false);
          if (status === 'OK' && results[0]) {
            const components = results[0].address_components;
            const city = components.find(component => component.types.includes('locality'));
            const country = components.find(component => component.types.includes('country'));

            if (city && country) setLocationName(`${city.long_name}, ${country.long_name}`);
            else if (!city) setLocationName(country.long_name);
            else if (!country) setLocationName(city.long_name);
          } else setLocationName(I18n.t('None'));
        }
      );
    else setLoading(false);
  }, []);

  if (loading) return <CircularProgress color={'secondary'} size={24} />;
  else if (locationName) return locationName;
  else return I18n.t('None');
}
